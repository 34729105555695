.m-gnb-list {
  width: 100%;
  position: fixed;
  top: var(--G-header-hegiht);
  left: 0;
  z-index: 1500;
  background-color: var(--header-bg);
  height: 0;
  overflow: hidden;
  transition: height 0.25s ease;
  &.isActive {
    height: 244px;
  }
  &__inner {
    @include flex;
    padding: 24px 0 40px;
    li {
      &:not(:last-of-type) {
        margin-bottom: 32px;
      }
      p {
        @include string(16px, normal, normal, 500, center, var(--text-primary));
        cursor: pointer;
      }
    }
  }
}
header {
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1400;
  .m-search-bar {
    display: none;
    @media only screen and (max-width: 1400px) {
      @include flex;
      background-color: var(--header-bg);
      height: var(--G-header-hegiht);
      border-top: 1px solid rgba(89, 89, 107, 0.50);
      padding: 0 16px;
    }
  }
  .navigation {
    @include flex;
    background-color: var(--header-bg);
    height: var(--G-header-hegiht);
    padding: 0 rem(220) 0 rem(46.55);
    @include breakpoint(ready) {
      padding: 0 rem(220, 40) 0 rem(46.55);
    }
    @include breakpoint(mobile) {
      padding: 0 16px;
    }
    &__inner {
      @include flex(space-between, center, row);
      @include size(100%, inherit);
      gap: rem(20);
      .actions {
        display: grid;
        align-items: center;
        &.double {
          display: grid;
          grid-template-columns: auto minmax(auto, rem(392));
          gap: rem(90);
          @media only screen and (max-width: 1400px) {
            grid-template-columns: auto;
            .search-input-wrapper {
              display: none;
            }
          }
        }
        .logo {
          img {
            object-fit: contain;
            cursor: pointer;
            @include same(100%);
          }
        }
        .defaultLogo {
          @include size(rem(174.19), rem(36));
          @include breakpoint(mobile) {
            display: none;
          }
        }
        .mobileLogo {
          display: none;
          @include breakpoint(mobile) {
            @include same(32px);
            display: block;
          }
        }
      }
      .menu-btn {
        @include same(24px);
        cursor: pointer;
      }
      .gnb-list {
        @include flex(flex-start, center, row);
        gap: rem(48);

        @include breakpoint(mobile) {
          display: none;
        }
        li {
          p {
            @include string(rem(16), normal, normal, 400, right, var(--text-primary));
            cursor: pointer;
          }
        }
      }
    }
  }
}