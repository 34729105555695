
.table-container {
  width: 100%;
  &__mobile-header {
    @include flex(flex-start, center, row);
    gap: 11px;
    margin-bottom: 12px;
    &.space {
      @include flex(space-between, center, row);
      gap: 0;
      padding-right: 32.5px;
    }
    li {
      p {
        @include string(12px, normal, normal, 500, left);
        text-transform: uppercase;
      }
    }
  }

  .m-table-inner {
    width: 100%;
    overflow: hidden;
    border-top: 1px solid var(--white);
    position: relative;
    &:not(.main) {
      min-height: 761px;
    }
    &__loading {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &__item {
      padding: 18px 0;
      border-bottom: 1px solid #59596B;
      .item-head {
        @include flex(space-between, center, row);
        margin-bottom: 11px;
        &__symbol {
          display: grid;
          grid-template-columns: 24px auto;
          gap: 12px;
          align-items: center;
          img {
            @include same(24px);
          }
          p {
            @include string(12px, normal, normal, 400, left);
          }
        }
        &__method {
          @include flex;
          border-radius: var(--s-box-radius);
          border: 1px solid #59596B;
          background: #151523;
          min-height: 23px;
          padding: 0 7px;
          width: 100%;
          max-width: 95px;
          box-sizing: border-box;
          overflow: hidden;
          p {
            @include string(11px, normal, normal, 400, center);
            width: 95%;
            word-break: break-all;
            overflow: hidden;
            white-space: nowrap;
            word-wrap: normal;
            text-overflow: ellipsis;
          }
        }
      }
      .item-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        li {
          @include flex(flex-start, flex-start);
          p {
            @include string(11px, normal, normal, 400, left, #7D7C97);
            margin-bottom: 3px;
          }
          span {
            @include string(12px, normal, normal, 400, left, #9E90F2);
            &.link {
              cursor: pointer;
            }
          }
          .fromTo {
            display: grid;
            grid-template-columns: 1fr 16px 1fr;
            gap: 8px;
            p {
              color: #9E90F2;
              word-break: break-all;
              overflow: hidden;
              white-space: nowrap;
              word-wrap: normal;
              text-overflow: ellipsis;
            }
            span {
              word-break: break-all;
              overflow: hidden;
              white-space: nowrap;
              word-wrap: normal;
              text-overflow: ellipsis;
            }
            .next-icon {
              @include same(16px);
              position: relative;
              &::after {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                content: '';
                display: block;
                @include same(20px);
                background: url($G-path-icon + 'fromToNext.svg') $G-img-contain;
              }
            }
          }
        }
      }
      .item-foot {
        @include flex(space-between, flex-end, row);
        margin-top: 11px;
        li {
          .first {
            margin-bottom: 4px;
          }
          p {
            @include string(12px, normal, normal, 400, left, var(--white));
          }
          span {
            @include string(11px, normal, normal, 400, left, #7D7C97);
          }
          .fee {
            @include flex(flex-end, center, row);
            gap: 5px;
          }
        }
      }
    }
  }
  .table-inner {
    width: 100%;
    overflow: hidden;
    table-layout: fixed;
    th, td {
      padding-left: 5px;
      padding-right: 5px;
    }
    tr {
      &:last-of-type {
        td {
          border-bottom: 1px solid var(--white);
        }
      }
    }
    td {
      border-bottom: 1px solid #59596B;
    }
    &__head {
      border-bottom: 1px solid var(--white);
      th {
        padding-bottom: 12px;
        vertical-align: middle;
        p {
          @include string(12px, normal, normal, 500, left);
          text-transform: uppercase;
        }
      }
    }
    &__loading {
      position: relative;
      .inner > .inner__progress {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      tr td {
        min-height: 59px;
        height: 59px;
        vertical-align: middle;
        border-bottom: 1px solid transparent;
      }
    }
    &__body {
      td {
        vertical-align: middle;
        min-height: 59px;
        height: 59px;
        p, span {
          @include string(12px, normal, normal, 400, left);
        }
        &:not(.two) {
          p {
            word-break: break-all;
            overflow: hidden;
            white-space: nowrap;
            word-wrap: normal;
            text-overflow: ellipsis;
            // width: 100%;
          }
        }
      }
    }

    th, td {
      &.fees, &.two {
        padding-right: 20px;
      }
    }
    .table-inner__head {
      th {
        &.chain, &.method {
          p {
            text-align: center;
          }
        }
        &.fees {
          p {
            text-align: right;
          }
        }
      }
    }
    .table-inner__body {
      td {
        &.symbol {
          text-align: center;
          img {
            @include same(24px);
          }
        }
        &.str {
          p {
            color: var(--text-helper);
          }
          .symbol-wrapper {
            display: grid;
            grid-template-columns: 24px auto;
            gap: 8px;
            align-items: center;
            img {
              @include same(24px);
            }
          }
        }
        &.w-90 {
          padding-right: 30px;
          @include breakpoint(tablet) {
            padding-right: 15px;
          }
        }
        &.concat {
          p {
            color: #9E90F2;
          }
        }
        &.column {
          .column__inner {
            @include flex(Flex-start, flex-start);
            p, span {
              font-size: 11px;
            }
            span {
              color: #7D7C97;
            }
            p {
              margin-bottom: 4px;
            }
          }
        }
        &.two {
          .two__inner {
            @include flex(flex-end, center, row);
            gap: 5px;
            p, span {
              color: var(--text-helper);
              text-align: right;
            }
            span {
              color: #7D7C97;
            }
          }
        }
      }
    }
  }
  &.managed-tx-list {
    .table-inner {
      th, td {
        &.amount {
          padding-right: 20px;
        }
      }
      colgroup {
        col {
          &.foundation, &.symbol {
            width: 14%;
          }
          &.age {
            width: 15%;
            @include breakpoint(tablet) {
              min-width: 100px;
              width: 13%;
            }
          }
          &.amount {
            width: 5%;
            min-width: calc(80px + 20px);
          }
          &.hash {
            // width: 20%;
          }
          &.from-to {
            // width: 28.5%;
            min-width: 150px;
          }
        }
      }
      .table-inner__head {
        th {
          &.amount {
            p {
              text-align: right;
            }
          }
          &.from-to {
            p {
              text-align: center;
            }
          }
        }
      }
      .table-inner__body {
        td {
          &.two {
            text-align: right;
          }
          &.fromTo {
            .fromTo__inner {
              @include flex(center, center, row);
              gap: 12px;
              p {
                color: #9E90F2;
              }
              .next-icon {
                @include same(24px);
                background: url($G-path-icon + 'fromToNext.svg') $G-img-contain;
              }
            }
          }
        }
      }
    }
  }
  &.latest-txs-list {
    min-height: 629px;
    @include breakpoint(mobile) {
      min-height: 761px;
    }
    .table-inner {
      colgroup {
        col {
          &.game {
            width: 11%;
          }
          &.method {
            width: 15%;
            max-width: 180px;
            min-width: 130px;
          }
          // &.fees {
          //   width: 8%;
          //   min-width: calc(80px + 20px);
          // }
          &.age {
            width: 17.5%;
            @include breakpoint(tablet) {
              min-width: 100px;
              width: 13%;
            }
          }
          &.chain {
            width: 6%;
            min-width: 50px;
          }
          &.hash {
            // width: 14%;
          }
          &.from-to {
            // width: 28.5%;
            min-width: 150px;
          }
        }
      }
      .table-inner__body {
        td {
          &.fromTo {
            .fromTo__inner {
              @include flex(flex-start, center, row);
              gap: 12px;
              p {
                color: #9E90F2;
              }
              .next-icon {
                @include same(24px);
                background: url($G-path-icon + 'fromToNext.svg') $G-img-contain;
              }
            }
          }
          &.method {
            .method__inner {
              @include flex;
              border-radius: var(--s-box-radius);
              border: 1px solid #59596B;
              background: #151523;
              min-height: 23px;
              // width: calc(100% - 16px);
              padding: 0 7px;
              width: 100%;
              max-width: 120px;
              box-sizing: border-box;
              overflow: hidden;
              margin: 0 auto;
              p {
                @include string(11px, normal, normal, 400, center);
                width: 95%;
              }
            }
          }
        }
      }
    }
  }
  &.latest-play-list {
    &:not(.main) {
      min-height: 629px;
      @include breakpoint(mobile) {
        min-height: 761px;
      }
    }
    .table-inner {
      colgroup {
        col {
          &.game {
            width: 11%;
          }
          &.fees {
            width: 8%;
            min-width: calc(80px + 20px);
          }
          &.age {
            width: 17.5%;
          }
          &.chain {
            width: 6%;
            min-width: 50px;
          }
        }
      }
    }
  }
}

.grid-row-layout {
  min-height: 50vh;
  &__body {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    @include breakpoint(tablet) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      gap: 40px;
    }
    .body-box {
      &__title {
        @include string(12px, normal, normal, 400, left, var(--white));
      }
      &__inner {
        margin-top: 17px;
        border-radius: var(--s-box-radius);
        background: #242431;
        padding: 26px 20px;
        @include breakpoint(mobile) {
          padding: 26px 16px;
        }
        .inner-select {
          @include flex(flex-start, center, row);
          gap: 8px;
          @include breakpoint(mobile) {
            padding: 0 0 0 4px;
          }
          .base-Button-root.small-btn {
            padding: 0 16px;
            width: auto;
          }
        }
        .inner-content {
          margin-top: 27px;
          @include breakpoint(mobile) {
            margin-top: 32px;
          }
          &.loading-wrapper {
            @include flex;
            min-height: 30vh;
          }
          .m-table-inner {
            width: 100%;
            overflow: hidden;
            &__header {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              align-items: center;
              padding-bottom: 12px;
              border-bottom: 1px solid var(--white);
              li {
                p {
                  @include string(12px, normal, normal, 500, left);
                }
              }
            }
            &__item {
              padding: 16px 6px;
              border-bottom: 1px solid #59596B;
              &:last-of-type {
                border-bottom: 1px solid var(--white);
              }
              .item-head {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                align-items: center;
                margin-bottom: 12px;
                &__symbol {
                  gap: 12px;
                  display: grid;
                  grid-template-columns: 32px auto;
                  align-items: center;
                  &--img {
                    @include same(32px);
                    @include flex;
                    position: relative;
                    .token {
                      @include same(100%);
                      &.nft {
                        border-radius: 2px;
                      }
                    }
                    .chain {
                      @include same(16px);
                      position: absolute;
                      bottom: -3.5px;
                      right: -4.5px;
                    }
                  }
                  &--str {
                    @include string(12px, normal, normal, 400, left);
                  }
                }
              }
              .item-list {
                display: grid;
                grid-template-columns: repeat(2, 1fr);

                &.column {
                  @include flex(space-between, center, row);
                  .column-inner {
                    &:not(:first-of-type, :last-of-type) {
                      p, span {
                        text-align: center;
                      }
                    }
                    &:first-of-type {
                      p, span {
                        text-align: left;
                      }
                    }
                    &:last-of-type {
                      p, span {
                        text-align: right;
                      }
                    }
                    @include flex;
                    width: 100%;
                    p, span {
                      width: 100%;
                    }
                    .in-list {
                      @include flex;
                      padding: 10px 0;
                      width: 100%;
                      li {
                        width: 100%;
                        &:not(:last-of-type) {
                          margin-bottom: 10px;
                          padding-bottom: 10px;
                          border-bottom: 1px solid #59596B;
                        }
                      }
                    }
                  }
                }
                li {
                  @include flex(flex-start, flex-start);
                  p {
                    @include string(11px, normal, normal, 400, left, #7D7C97);
                    margin-bottom: 3px;
                  }
                  span {
                    @include string(12px, normal, normal, 400, left);
                  }
                  .fromTo {
                    display: grid;
                    grid-template-columns: 1fr 16px 1fr;
                    gap: 8px;
                    p {
                      color: #9E90F2;
                      word-break: break-all;
                      overflow: hidden;
                      white-space: nowrap;
                      word-wrap: normal;
                      text-overflow: ellipsis;
                    }
                    .next-icon {
                      @include same(16px);
                      position: relative;
                      &::after {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        content: '';
                        display: block;
                        @include same(20px);
                        background: url($G-path-icon + 'fromToNext.svg') $G-img-contain;
                      }
                    }
                  }
                }
              }
              .item-foot {
                @include flex(space-between, flex-end, row);
                margin-top: 11px;
                li {
                  .first {
                    margin-bottom: 4px;
                  }
                  p {
                    @include string(12px, normal, normal, 400, left, var(--white));
                  }
                  span {
                    @include string(11px, normal, normal, 400, left, #7D7C97);
                  }
                  .fee {
                    @include flex(flex-end, center, row);
                    gap: 5px;
                  }
                }
              }
            }
          }
          .table-inner {
            width: 100%;
            overflow: hidden;
            table-layout: fixed;
            th, td {
              padding-left: 5px;
              padding-right: 5px;
            }
            tr {
              &:last-of-type {
                td {
                  border-bottom: 1px solid var(--white);
                }
              }
            }
            td {
              border-bottom: 1px solid #59596B;
            }
            &__head {
              border-bottom: 1px solid var(--white);
              th {
                padding-bottom: 12px;
                vertical-align: middle;
                p {
                  @include string(12px, normal, normal, 400, left);
                }
              }
            }
            &__body {
              td {
                vertical-align: middle;
                min-height: 64px;
                height: 64px;
                p, span {
                  @include string(12px, normal, normal, 400, left);
                }
                &:not(.two) {
                  p {
                    word-break: break-all;
                    overflow: hidden;
                    white-space: nowrap;
                    word-wrap: normal;
                    text-overflow: ellipsis;
                    // width: 100%;
                  }
                }
              }
            }
            .table-inner__head {
              th {
                &.Token {
                  padding-left: 12px;
                }
                &.Transfers, &.Holders, &.Session, &.TotalSupply, &.Staked {
                  p {
                    text-align: center;
                  }
                }
              }
            }
            .table-inner__body {
              td {
                &.symbol {
                  padding-left: 12px;
                  .symbol__inner {
                    gap: 12px;
                    display: grid;
                    grid-template-columns: 32px auto;
                    align-items: center;
                    &--img {
                      @include same(32px);
                      @include flex;
                      position: relative;
                      .token {
                        @include same(100%);
                        &.nft {
                          border-radius: 2px;
                        }
                      }
                      .chain {
                        @include same(16px);
                        position: absolute;
                        bottom: -3.5px;
                        right: -4.5px;
                      }
                    }
                  }
                }
                &.str {
                  p {
                    text-align: center;
                  }
                }
                &.concat {
                  p {
                    color: #9E90F2;
                  }
                }
                &.list {
                  ul {
                    @include flex;
                    padding: 18px 0;
                    li {
                      &:not(:last-of-type) {
                        margin-bottom: 15px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.detail-layout {
  &__body {
    display: grid;
    grid-auto-rows: auto;
    gap: 24px;
    .body-box {
      border-radius: var(--s-box-radius);
      background: #242431;
      padding: 24px;
      @include breakpoint(mobile) {
        padding: 24px 20px;
      }
      &__loading {
        @include flex;
        min-height: 309px;
        @include breakpoint(mobile) {
          min-height: 389.57px;
        }
      }
      &__list {
        &:not(:last-of-type) {
          margin-bottom: 25px;
          padding-bottom: 25px;
          border-bottom: 1px solid #59596B;
          @include breakpoint(mobile) {
            margin-bottom: 24px;
            padding-bottom: 24px;
          }
        }
        li {
          @include flex(flex-start, center, row);
          gap: 2vw;
          flex-wrap: wrap;
          min-height: 24px;
          &:not(:last-of-type) {
            margin-bottom: 18px;
          }
          .title {
            display: grid;
            grid-template-columns: 16px auto;
            gap: 4px;
            align-items: center;
            min-width: 20vw;
            &.no-icon {
              display: block;
            }
            &__text {
              @include string(14px, normal, normal, 600, left, #7D7C97);
            }
            .method-icon {
              @include same(16px);
              background: url($G-path-icon + 'bolt.svg') $G-img-contain;
            }
          }
          .inner {
            &.rows {
              @include flex(flex-start, center, row);
              gap: 4px;
              @include breakpoint(mobile) {
                flex-direction: column;
                align-items: flex-start;
              }
              span {
                @include string(12px, normal, normal, 400, left, #7D7C97);
              }
            }
            p {
              font-size: 14px;
              line-height: normal;
              letter-spacing: normal;
              font-weight: 400;
              word-break: break-all;
            }
            .btn {
              color: #9E90F2 !important;
              cursor: pointer;
              font-size: 14px !important;
            }
            .copy-item-wrapper p {
              font-size: 14px;
            }
            .symbol {
              display: grid;
              grid-template-columns: 24px auto;
              gap: 8px;
              align-items: center;
              img {
                @include same(24px);
              }
            }
            .status {
              display: grid;
              grid-template-columns: 12px auto;
              gap: 2px;
              align-items: center;
              height: 24px;
              padding: 0 8px;
              box-sizing: border-box;
              border-radius: 4px;
              border: 1px solid rgba(0, 161, 134, 0.50);
              background: rgba(0, 161, 134, 0.10);
              span {
                @include string(11px, 12px, normal, 400, left, #00A186);
              }
              @each $icon in Success {
                &.#{$icon} {
                  &::before {
                    @include same(12px);
                    background: url($G-path-icon + '#{$icon}.svg') $G-img-contain;
                    content: '';
                    display: block;
                  }
                }
              }
            }
            .time {
              display: grid;
              grid-template-columns: 16px auto auto;
              gap: 4px;
              &::before {
                @include same(16px);
                background: url($G-path-icon + 'time.svg') $G-img-contain;
                content: '';
                display: block;
                transform: translateY(8%);
              }
            }
          }
        }
      }
    }
  }
}