.tooltip-wrapper {
  position: relative;
  .tooltip-inner {
    @include flex;
  }
  .tooltip-item {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%) translateY(-3px);
    background: var(--white);
    border-radius: 2px;
    &__inner {
      padding: 8px 16px;
      max-width: 230px;
      &::after {
        @include same(7.5px);
        content: '';
        display: block;
        background-color: var(--white);
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%) translateY(3.75px) rotate(-45deg);
      }
      p {
        font-size: 12px !important;
        line-height: 18px !important;
        letter-spacing: 0.16px !important;
        font-weight: 500 !important;
        text-align: left !important;
        color: #000 !important;
      }
    }
  }
}

.recharts-tooltip-wrapper {
  &::after {
    @include same(7.5px);
    content: '';
    display: block;
    background-color: var(--white);
    position: absolute;
    // bottom: 0;
    // left: 50%;
    // transform: translateX(-50%) translateY(3.75px) rotate(-45deg);
  }
  &.recharts-tooltip-wrapper-left::after {
    right: 0;
    top: 50%;
    transform: translateX(50%) translateY(-50%) rotate(-45deg);
  }
  &.recharts-tooltip-wrapper-right::after {
    left: 0;
    top: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  }
}
.custom-tooltip {
  background: var(--white);
  border-radius: 2px;
  &__date {
    padding: 8px 16px;
    font-size: 12px !important;
    line-height: 18px !important;
    letter-spacing: 0.16px !important;
    font-weight: 500 !important;
    text-align: left !important;
    color: #000 !important;
  }
  ul {
    padding: 0 16px 8px;
    li {
      font-size: 12px !important;
      line-height: 18px !important;
      letter-spacing: 0.16px !important;
      font-weight: 500 !important;
      text-align: left !important;
      color: #000 !important;
    }
    li.small {
      font-size: 10px !important;
      line-height: 12px !important;
    }
  }
}
.chart-wrapper {
  .chart-header {
    @include flex(space-between, center, row);
    @media only screen and (max-width: 860px) {
      @include flex(flex-start, flex-start);
    }
    &__default {
      .title {
        @include flex(flex-start, center, row);
        gap: 8px;
        @media only screen and (max-width: 860px) {
          @include flex(flex-start, flex-start);
          gap: 0;
        }
        p {
          @include string(16px, normal, normal, 500, left, var(--white));
        }
        span {
          @include string(12px, normal, normal, 400, left, #7D7C97);
          transform: translateX(1px);
          display: inline-block;
          @media only screen and (max-width: 860px) {
            margin-top: 4px;
            transform: translateX(0px);
          }
        }
      }
    }
    &__option {
      display: grid;
      grid-template-columns: 153px 196px;
      gap: 16px;
      align-items: center;
      @media only screen and (max-width: 860px) {
        width: 100%;
        grid-template-columns: 1fr 1.5fr;
        gap: 8px;
        margin-bottom: 32px;
      }
      &.solo {
        grid-template-columns: 153px;
        @media only screen and (max-width: 860px) {
          margin-top: 20px;
          grid-template-columns: 1fr;
        }
      }
      .select-wrapper {
        @include flex(flex-start, flex-start);
        .select-title {
          @include string(12px, normal, normal, 400, left, #7D7C97);
          margin-bottom: 8px;
        }
      }
    }
  }
  .chart-nodata {
    &__inner {
      @include flex;
      padding: rem(160) 0 rem(198);
      p {
        @include string(16px, normal, normal, 600, center);
        margin-bottom: 4px;
      }
      span {
        @include string(12px, normal, normal, 400, center, #7D7C97);
      }
    }
  }
}
.chart-select {
  @include flex(flex-start, center, row);
  flex-wrap: wrap;
  gap: 8px;
  margin: 12px 0 24px;
}
.chart-box {
  position: relative;
  .progress-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .recharts-cartesian-axis-tick {
    .recharts-cartesian-axis-tick-line {
      stroke: transparent;
    }
  }
  .recharts-layer.recharts-cartesian-axis {
    .recharts-cartesian-axis-line {
      stroke: var(--white);
    }
  }
  .recharts-cartesian-grid .recharts-cartesian-grid-horizontal line,
  .recharts-cartesian-grid .recharts-cartesian-grid-vertical line {
    &:last-of-type {
      stroke: transparent;
    }
  }
  &.small-chart-box {
    height: 140px;
  }
  &.large-chart-box {
    height: 470px;
    @include breakpoint(mobile) {
      height: 340px;
    }
    &.no-legend {
      height: 430px;
      @include breakpoint(mobile) {
        height: 230px;
      }
    }
    &.child-box {
      margin-top: 60px;
      @include breakpoint(mobile) {
        margin-top: 40px;
      }
    }
    &.last-padding {
      padding-bottom: 20px;
      @include breakpoint(mobile) {
        padding-bottom: 10px;
      }
    }
  }
  .recharts-legend-wrapper {
    .recharts-default-legend {
      @include flex(center, center, row);
      gap: rem(37);
      @media only screen and (max-width: 1100px) {
        flex-wrap: wrap;
        gap: 0 20px;
      }
      > .recharts-legend-item {
        margin-right: 0 !important;
        .recharts-surface {
          margin-right: 8px !important;
        }
        .recharts-legend-item-text {
          color: #fff !important;
        }
      }
    }
  }
}