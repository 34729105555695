
/* search */
.search-wrapper {
  position: relative;
  width: 100%;
  &.large {
    height: 48px;
  }
  &.Small {
    height: 40px;
    @include breakpoint(mobile) {
      height: 34px;
    }
  }
  .search-input-wrapper {
    @include flex(flex-start, flex-start);
    width: 100%;
    border: 1px solid #644CF4;
    box-shadow: 0px 0px 24px 0px rgba(100, 76, 244, 0.50);
    border-radius: var(--s-box-radius);
    position: absolute;
    z-index: 15;
    top: 0;
    left: 0;
    .search-details {
      @include scrollCustom;
      @include flex(flex-start, center);
      max-height: 200px;
      overflow-y: auto;
      width: 100%;
      background-color: #0E0E18;
      box-sizing: border-box;
      @include breakpoint(mobile) {
        max-height: 150px;
      }
      &__inner {
        width: calc(100% - 32px);
        border-top: 1px solid rgba(89, 89, 107, 0.50);
        box-sizing: border-box;
        padding: 14px 0;
        .infos-wrap {
          @include flex(center, center);
          min-height: 50px;
          @include breakpoint(mobile) {
            min-height: 30px;
          }
          p {
            @include string(14px, normal, normal, 400, left, var(--white));
            opacity: 0.5;
            @include breakpoint(mobile) {
              font-size: 12px !important;
            }
          }
        }
        .result {
          &__list {
            li {
              display: grid;
              grid-template-columns: 20px auto 16px;
              align-items: center;
              gap: 8px;
              justify-content: flex-start;
              overflow: hidden;
              &:not(:last-of-type) {
                margin-bottom: 12px;
              }
              p {
                @include string(16px, normal, normal, 400, left, var(--white));
                word-break: break-all;
                overflow: hidden;
                white-space: nowrap;
                word-wrap: normal;
                text-overflow: ellipsis;
                @include breakpoint(mobile) {
                  font-size: 14px !important;
                }
              }
              .symbol {
                @include same(20px);
              }
              .link {
                @include same(16px);
                cursor: pointer;
              }
            }
          }
        }
        .loading-wrap {
          @include flex;
          min-height: 50px;
        }
      }
    }
    &.Small {
      border: 1px solid rgba(100, 76, 244, 0.50);
      box-shadow: none;
    }
    &.large {
      .MuiInputBase-root {
        @include string(16px, 21px, normal, 500, left, var(--white));
        height: 48px;
        max-height: 48px;
        max-width: 100%;
        @include breakpoint(mobile) {
          @include string(14px, 18px, normal, 400, left, var(--white));
        }
        .MuiInputBase-input {
          min-height: 21px;
          height: 21px;
          @include breakpoint(mobile) {
            min-height: 18px;
            height: 18px;
          }
          &::placeholder {
            @include string(16px, 21px, normal, 500, left, var(--white));
            @include breakpoint(mobile) {
              @include string(14px, 18px, normal, 400, left, var(--white));
            }
          }
        }
        .search-icon {
          @include breakpoint(mobile) {
            @include same(24px);
            min-width: 24px;
          }
        }
      }
    }
    .MuiInputBase-root {
      @include string(14px, 18px, normal, 500, left, var(--white));
      width: 100%;
      height: 40px;
      max-height: 40px;
      // max-width: rem(392);
      background-color: #0E0E18;
      box-sizing: border-box;
      padding: 0 16px;
      @include breakpoint(mobile) {
        max-width: 100%;
        height: 32px;
        max-height: 32px;
        padding: 0 8px 0 16px;
      }
      .search-icon {
        @include flex;
        @include same(24px);
        min-width: 24px;
        // cursor: pointer;
        @include breakpoint(mobile)  {
          @include same(16px);
          min-width: 16px;
        }
        &::before {
          @include same(100%);
          content: '';
          display: block;
          background: url($G-path-icon + 'explore.svg') $G-img-contain;
        }
      }
      .MuiInputBase-input {
        min-height: 18px;
        height: 18px;
        padding: 0;
        &::placeholder {
          @include string(14px, 18px, normal, 500, left, #7D7C97);
          opacity: 1;
        }
      }
      // &:hover .MuiOutlinedInput-notchedOutline {
      //   border-color: var(--border-subtle-01) !important;
      //   border-width: 1px;
      // }
      &.Mui-focused {
        .MuiOutlinedInput-notchedOutline {
          border: none !important;
          // border-color: rgba(100, 76, 244, 0.50) !important;
          // border-width: 1px;
        }
      }
    }
  }
}

.base-Button-root {
  &.small-btn {
    padding: 0 10px;
    height: 32px;
    width: 54px;
    border-radius: var(--s-box-radius);
    border: 1px solid #59596B;
    background-color: transparent;
    &.Mui-disabled {
      cursor: default;
      opacity: 0.5;
    }
    &.isActive {
      border: 1px solid #9E90F2;
      background-color: #9E90F2;
      p {
        color: #242431;
        font-weight: 700;
      }
    }
    p {
      @include string(12px, normal, normal, 500, center, var(--white));
    }
  }
  &.base-btn {
    padding: 0 10px;
    height: 40px;
    border-radius: var(--s-box-radius);
    position: relative;
    border: 1px solid var(--white);
    background-color: #242431;
    &.Mui-disabled {
      cursor: default;
      opacity: 0.5;
    }
    .loading-wrap {
      @include same(20px);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    p {
      @include string(16px, 16px, normal, 500, center, var(--white));
      &.hidden {
        opacity: 0;
      }
    }
  }

}
/* copy style */
.copy-item-wrapper {
  p {
    @include string(12px, normal, normal, 400, left, #9E90F2);
    word-break: break-all;
    overflow: hidden;
    white-space: nowrap;
    word-wrap: normal;
    text-overflow: ellipsis;
  }
  &.start {
    justify-content: flex-start;
    gap: 4px;
    @include breakpoint(tablet) {
      display: grid;
      grid-template-columns: auto 16px;
      gap: 4px;
    }
  }
  &.between {
    display: grid;
    grid-template-columns: auto 16px;
    gap: 4px;
    @include breakpoint(tablet) {
      grid-template-columns: auto 16px;
      gap: 4px;
    }
  }
  display: flex;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  .copy-icon {
    @include same(16px);
    background: url($G-path-icon + 'copy.svg') $G-img-contain;
  }
}

/* pagenation custom */
.pagenation-wrapper {
  @include flex(flex-end, center, row);
  &.center {
    justify-content: center;
  }
  @include breakpoint(mobile) {
    @include flex;
  }
}
.MuiPagination-root.pagenation-custom {
  @include flex;
  margin-top: 35px;
  .MuiPagination-ul {
    gap: 8px;
    li {
      @include flex;
      // width: 16px;
      // height: 16px;
      .MuiPaginationItem-ellipsis {
        @include string(12px, normal, normal, 400, center);
        color: rgba(255, 255, 255, .5);
      }
      .MuiButtonBase-root {
        @include string(12px, normal, normal, 400, center);
        color: rgba(255, 255, 255, .5);
        margin: 0;
        min-width: 16px;
        height: 12px;
        &.Mui-selected {
          color: rgba(255, 255, 255, 1);
        }
        .MuiPaginationItem-icon {
          width: 20px;
          height: 20px;
          font-size: 16px;
        }
      }
    }
  }
}

/* select */
.MuiFormControl-root.base-select-wrapper {
  .MuiFormLabel-root {
    @include string(14px, 14px, 0.16px, 400, left, var(--text-placeholder));
    top: 50%;
    transform: translateY(-50%);
    left: 16px;
    transform-origin: 0 0;
    &.MuiInputLabel-shrink {
      // opacity: 0;
    }
  }
}
.MuiInputBase-root.base-select {
  background: #242431;
  border-radius: 0px;
  &.default-select {
    width: 100%;
    .MuiSelect-select {
      @include flex(flex-start, center, row);
      padding: 8px 16px 8px 16px;
      border-radius: var(--s-box-radius);
      border: 1px solid #59596B;
      overflow: hidden;
      &[aria-expanded=true] {
        border-radius: var(--s-box-radius) var(--s-box-radius) 0 0;
        border-bottom: 1px solid rgba(89, 89, 107, 0.3);
      }
      .title {
        @include string(12px, 12px, normal, 600, left);
      }
    }
  }
  .MuiOutlinedInput-notchedOutline {
    display: none; // focus no style
  }
  .MuiSelect-select.MuiSelect-select {
    min-height: calc(36px - 16px);
    max-height: calc(36px - 16px);
    // width: rem(288);
    @include breakpoint(mobile) {
      // width: 95px;
    }
  }
  .MuiSvgIcon-root {
    color: #fff;
  }
}

.MuiPaper-root.MuiMenu-paper {
  @include scrollCustomSmall;
  background-color: #242431 !important;
  box-shadow: none !important;
  max-height: 300px !important;
  border-left: 1px solid #59596B !important;
  border-right: 1px solid #59596B !important;
  border-bottom: 1px solid #59596B !important;
  border-radius: 0 0 var(--s-box-radius) var(--s-box-radius) !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  box-sizing: border-box !important;
  @include breakpoint(tablet) {
    max-height: 250px !important;
  }
  .MuiList-padding.MuiMenu-list {
    padding: 0 !important;
    width: 100% !important;
  }
}

.base-date-picker-wrapper {
  @include flex(flex-end, center, row);
}

.MuiFormControl-root.base-date-picker {
  max-width: 150px;
  .MuiSvgIcon-root {
    fill: #fff;
    width: 18px;
    height: 18px;
    opacity: 0.8;
  }
  .MuiOutlinedInput-notchedOutline {
    display: none !important;
  }
  .MuiInputBase-root {
    @include string(12px, 12px, normal, 600, left, var(--text-primary));
    width: 100%;
    border-radius: var(--s-box-radius);
    background-color: #242431;
    border: 1px solid #59596B;
    box-sizing: border-box;
    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: #59596B !important;
      border-width: 1px;
    }
  }
  .MuiInputLabel-root{
    @include string(12px, 12px, normal, 600, left, var(--text-placeholder));
    &.MuiInputLabel-shrink {
      display: none !important;
    }
    &.Mui-focused {
      display: none !important;
    }
  }
}

.MuiPaper-root.MuiPickersPopper-paper {
  background-color: #242431;
  border: 1px solid #59596B;
  box-sizing: border-box;
  * {
    color: #fff;
  }

}