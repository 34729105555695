.home-wrapper {
  padding-bottom: 30vh;
  @include breakpoint(mobile) {
    padding-bottom: 150px;
  }
  &__bg {
    position: relative;
    z-index: 1;
    &::after {
      display: block;
      content: '';
      position: absolute;
      left: -2000px;
      right: -2000px;
      top: 0;
      bottom: 0;
      z-index: -1;
      opacity: 0.7;
      background: url($G-path-content + 'home-bg.png'), #0C0C13 50% / cover no-repeat;
      height: 320px;
    }
  }
  &__head {
    @include flex(flex-start, flex-start);
    padding-top: rem(85);
    position: relative;
    z-index: 2;
    @include breakpoint(mobile) {
      @include flex(flex-start);
      padding-top: 54px;
    }
    .logo {
      object-fit: contain;
      height: rem(41);
      margin-bottom: rem(25);
      @include breakpoint(mobile) {
        height: 27px;
        margin-bottom: 22px;
      }
    }
  }
  .home-inner {
    position: relative;
    z-index: 1;
    margin-top: rem(43);
    display: grid;
    grid-auto-rows: auto;
    gap: 25px;
    @media only screen and (max-width: 960px) {
      margin-top: 56px;
      gap: 23px;
    }
    .row {
      display: grid;
      gap: 16px;
      grid-template-columns: repeat(2, 1fr);
      @media only screen and (max-width: 1400px) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
      }
    }
    .container-box {
      border-radius: var(--box-radius);
      background: #242431;
      .table-wrapper-head {
        margin-top: 50px;
      }
      &__title {
        @include string(16px, normal, normal, 500, left, var(--white));
      }
      &.top {
        padding: 20px 20px 16px;
        display: grid;
        grid-auto-rows: auto;
        gap: 16px;
        @media only screen and (max-width: 960px) {
          gap: 25px;
        }
        .top__inner {
          display: grid;
          justify-content: space-between;
          gap: 20px;
          grid-template-columns: repeat(3, 1fr);
          @media only screen and (max-width: 1400px) {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
          }
          @media only screen and (max-width: 960px) {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;
          }
          .double, .solo {
            padding-top: 5px;
            padding-bottom: 5px;
            @media only screen and (max-width: 960px) {
              padding-top: 0;
              &:not(.chart) {
                padding-bottom: 20px;
              }
            }
          }
          .chart {
            &__title {
              @include string(16px, normal, normal, 500, left, var(--white));
            }
          }
          .solo {
            @media only screen and (max-width: 1400px) {
              grid-area: 2 / 1 / 3 / 3;
            }
            @media only screen and (max-width: 960px) {
              grid-area: auto;
            }
          }
          .double {
            display: grid;
            grid-auto-rows: auto;
            border-right: 1px solid #59596B;
            padding-right: 20px;
            @media only screen and (max-width: 1400px) {
              &:last-of-type {
                border-right: none;
                padding-right: 0;
              }
            }
            @media only screen and (max-width: 960px) {
              border-right: none;
              border-bottom: 1px solid #59596B;
              width: 100%;
              padding-right: 0;
            }
            li {
              @include flex(space-between, flex-start);
              min-height: 57px;
              @media only screen and (max-width: 960px) {
                min-height: auto;
                width: 100%;
              }
              &:first-of-type {
                margin-bottom: 20px;
                padding-bottom: 20px;
                border-bottom: 1px solid #59596B;
              }
              p {
                @include string(16px, normal, normal, 500, left, var(--white));
              }
              &.desc {
                p {
                  &:last-of-type {
                    margin-top: 12px;
                  }
                }
              }
            }
            .price {
              &__title {
                display: grid;
                grid-template-columns: 24px auto;
                gap: 8px;
                align-items: center;
                img {
                  @include same(24px);
                }
              }
              &__inner {
                @include flex(space-between, center, row);
                width: 100%;
                @media only screen and (max-width: 960px) {
                  @include flex(flex-start, flex-start);
                  margin-top: 14px;
                  .price {
                    margin-bottom: 4px;
                  }
                }
                .price, .conversion {
                  @include flex(flex-start, center, row);
                  gap: 6px;
                  span {
                    @include string(16px, normal, normal, 500, left);
                    &.plus {
                      color: #00A186;
                    }
                    &.minus {
                      color: #E9415F;
                    }
                    &.normal {
                      color: #fff;
                      opacity: 0.5;
                    }
                  }
                }
              }
            }
            .supply {
              &__title {
                @include flex(flex-start, center, row);
                gap: 4px;
              }
              &__inner {
                @media only screen and (max-width: 960px) {
                  margin-top: 12px;
                }
              }
            }
          }
        }
      }
      &.foundation {
        padding: 20px 20px 30px;
        @media only screen and (max-width: 960px) {
          padding: 20px;
        }
        .foundation__inner {
          &.transfers {
            margin-top: 25px;
            @media only screen and (max-width: 960px) {
              margin-top: 12px;
            }
          }
          &.address {
            margin: 31px 0 59px;
            display: grid;
            grid-auto-rows: auto;
            @media only screen and (max-width: 960px) {
              gap: 20px;
              margin: 22px 0 40px;
            }
            .row-item {
              display: grid;
              grid-template-columns: repeat(3, 1fr);
              gap: 20px;
              @media only screen and (max-width: 960px) {
                grid-template-columns: 1fr;
                grid-template-rows: 1fr;
                &:last-of-type li:last-of-type {
                  @media only screen and (max-width: 960px) {
                    border-bottom: none;
                    padding-bottom: 0;
                  }
                }
              }
              &:not(:first-of-type) {
                > li {
                  padding-top: 20px;
                  @media only screen and (max-width: 960px) {
                    padding-top: 0;
                  }
                }
              }
              &:not(:last-of-type) {
                > li {
                  &::after {
                    content: '';
                    display: block;
                    margin-top: 20px;
                    height: 1px;
                    width: calc(100% - 20px);
                    background-color: #59596B;
                    @media only screen and (max-width: 960px) {
                      display: none;
                    }
                  }
                }
              }
              .hide {
                @media only screen and (max-width: 960px) {
                  display: none;
                }
              }
              > li {
                @media only screen and (max-width: 960px)  {
                  border-bottom: 1px solid #59596B;
                  padding-bottom: 20px;
                }
                &:not(:last-of-type) {
                  border-right: 1px solid #59596B;
                  @media only screen and (max-width: 960px) {
                    border-right: none;
                  }
                }
                @include flex(flex-start, flex-start);

                .title {
                  @include flex(flex-start, center, row);
                  gap: 4px;
                  .link-icon {
                    cursor: pointer;
                  }
                }
                .inner {
                  margin-top: 12px;
                }
                .title, .inner {
                  p {
                    @include string(16px, normal, normal, 600, left);
                  }
                }
              }
            }
          }
        }
      }
      &.center {
        padding: 15px 20px;
      }
      &.support {
        padding: 23px 20px 22px;
        @include breakpoint(mobile) {
          padding: 20px 16px 24px;
        }
        .support__inner {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          @media only screen and (max-width: 1100px) {
            grid-template-columns: 1fr;
            grid-auto-rows: auto;
          }
          .box {
            @include flex(flex-start, center, row);
            @media only screen and (max-width: 1100px) {
              flex-direction: column;
              align-items: flex-start;
            }
            &:last-of-type {
              &::before {
                @include size(1px, 45px);
                content: '';
                display: block;
                background-color: #59596B;
                margin-right: 28px;
                @media only screen and (max-width: 1100px) {
                  @include size(100%, 1px);
                  margin: 20px 0;
                }
              }
            }
            p {
              @include string(16px, normal, normal, 500, left);
            }
            ul {
              @include flex(flex-start, center, row);
              gap: 16px;
              height: 32px;
              margin-left: 24px;
              @media only screen and (max-width: 1100px) {
                margin: 16px 0 0;
              }
              li {
                height: 32px;
                img {
                  @include same(100%);
                  object-fit: contain;
                }
              }
            }
          }
        }
      }
      &.foot {
        padding: 20px 20px 15px;
        display: grid;
        grid-template-rows: 475px auto;
        gap: 14px;
        @include breakpoint(mobile) {
          padding: 20px 20px 16px;
          grid-template-rows: 1fr auto;
        }
        .foot__inner {
          overflow: hidden;
        }
        .table-container {
          .small-table-inner {
            width: 100%;
            overflow: hidden;
            table-layout: fixed;
            th, td {
              padding-left: 5px;
              padding-right: 5px;
            }
            td {
              border-bottom: 1px solid #59596B;
            }
            &__head {
              border-bottom: 1px solid var(--white);
              position: relative;
              z-index: 10;
              background-color: #242431;
              th {
                padding-bottom: 12px;
                vertical-align: middle;
                p {
                  @include string(12px, normal, normal, 400, left, var(--text-helper));
                  text-transform: uppercase;
                }
              }
            }
            &__body {
              overflow: hidden;
              max-height: 400px;
              td {
                vertical-align: middle;
                min-height: 39px;
                height: 39px;
                p, span {
                  @include string(12px, normal, normal, 400, left);
                }
                p {
                  word-break: break-all;
                  overflow: hidden;
                  white-space: nowrap;
                  word-wrap: normal;
                  text-overflow: ellipsis;
                  width: 90%;
                }
              }
            }
          }
          &.latest-play-list {
            .m-table-inner {
              margin-top: 20px;
              &__item {
                .item-head__fee {
                  @include flex(flex-end, center, row);
                  gap: 5px;
                  p {
                    @include string(12px, normal, normal, 400, right);
                  }
                  span {
                    @include string(11px, normal, normal, 400, right, #7D7C97);
                  }
                }
                .item-list {
                  display: grid;
                  grid-template-columns: repeat(2, 1fr);
                  li {
                    @include flex(flex-start, flex-start);
                    p {
                      @include string(11px, normal, normal, 400, left, #7D7C97);
                      margin-bottom: 3px;
                    }
                    span {
                      @include string(12px, normal, normal, 400, left, #9E90F2);
                    }
                  }
                }
              }
            }
            .small-table-inner {
              margin-top: 25px;
              colgroup {
                col {
                  // &.fees {
                  //   width: 17.5%;
                  // }
                  // &.hash, &.from, &.game {
                    // width: 24.16%;
                  // }
                  &.chain {
                    width: 10%;
                  }
                }
              }
              th, td {
                &.fees, &.two {
                  padding-right: 20px;
                }
              }
              .small-table-inner__head {
                th {
                  &.chain {
                    p {
                      text-align: center;
                    }
                  }
                  &.fees {
                    p {
                      text-align: right;
                    }
                  }
                }
              }
              .small-table-inner__body {
                td {
                  &.symbol {
                    text-align: center;
                    img {
                      @include same(24px);
                    }
                  }
                  &.str {
                    p {
                      color: var(--text-helper);
                    }
                  }
                  &.concat {
                    p {
                      color: #9E90F2;
                    }
                  }
                  &.link {
                    p {
                      cursor: pointer;
                    }
                  }
                  &.two {
                    .two__inner {
                      @include flex(flex-end, center, row);
                      gap: 5px;
                      p, span {
                        color: var(--text-helper);
                        text-align: right;
                      }
                      span {
                        color: #7D7C97;
                      }
                    }
                  }
                }
              }
            }
          }
          &.latest-transaction-list {
            .m-table-inner {
              margin-top: 20px;
              &__item {
                .item-head__method {
                  @include flex;
                  border-radius: var(--s-box-radius);
                  border: 1px solid #59596B;
                  background: #151523;
                  min-height: 23px;
                  padding: 0 8px;
                  width: 100%;
                  max-width: 95px;
                  box-sizing: border-box;
                  overflow: hidden;
                  p {
                    @include string(11px, normal, normal, 400, center);
                    word-break: break-all;
                    overflow: hidden;
                    white-space: nowrap;
                    word-wrap: normal;
                    text-overflow: ellipsis;
                    width: 100%;
                  }
                }
                .item-list {
                  display: grid;
                  grid-template-columns: repeat(2, 1fr);
                  li {
                    @include flex(flex-start, flex-start);
                    p {
                      @include string(11px, normal, normal, 400, left, #7D7C97);
                      margin-bottom: 3px;
                    }
                    span {
                      @include string(12px, normal, normal, 400, left, #9E90F2);
                    }
                    .fromTo {
                      display: grid;
                      grid-template-columns: 1fr 16px 1fr;
                      align-items: center;
                      gap: 10px;
                      p {
                        color: #9E90F2;
                      }
                      .next-icon {
                        @include same(16px);
                        position: relative;
                        &::after {
                          position: absolute;
                          top: 50%;
                          left: 50%;
                          transform: translate(-50%, -50%);
                          content: '';
                          display: block;
                          @include same(20px);
                          background: url($G-path-icon + 'fromToNext.svg') $G-img-contain;
                        }
                      }
                      span {
                        word-break: break-all;
                        overflow: hidden;
                        white-space: nowrap;
                        word-wrap: normal;
                        text-overflow: ellipsis;
                      }
                    }
                  }
                }
              }
            }
            .small-table-inner {
              margin-top: 25px;
              colgroup {
                col {
                  &.chain {
                    width: 10%;
                  }
                  &.game, &.hash {
                    width: 22%;
                  }
                  &.method {
                    width: 17.5%;
                  }
                  &.from-to {
                    width: 28.5%;
                  }
                }
              }
              .small-table-inner__head {
                th {
                  &.chain, &.method {
                    p {
                      text-align: center;
                    }
                  }
                  &.from-to {
                    padding-left: 15px;
                  }
                }
              }
              .small-table-inner__body {
                td {
                  &.symbol {
                    text-align: center;
                    img {
                      @include same(24px);
                    }
                  }
                  &.str {
                    p {
                      color: var(--text-helper);
                    }
                  }
                  &.concat {
                    p {
                      color: #9E90F2;
                    }
                  }
                  &.method {
                    .method__inner {
                      @include flex;
                      border-radius: var(--s-box-radius);
                      border: 1px solid #59596B;
                      background: #151523;
                      min-height: 23px;
                      // width: calc(100% - 16px);
                      padding: 0 8px;
                      width: 100%;
                      box-sizing: border-box;
                      overflow: hidden;
                      p {
                        @include string(11px, normal, normal, 400, center);
                      }
                    }
                  }
                  &.fromTo {
                    padding-left: 15px;
                    .fromTo__inner {
                      display: grid;
                      grid-template-columns: 1fr 24px 1fr;
                      align-items: center;
                      gap: 8px;
                      p {
                        color: #9E90F2;
                      }
                      .next-icon {
                        @include same(24px);
                        background: url($G-path-icon + 'fromToNext.svg') $G-img-contain;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.resources {
  .resources-inner {
    display: grid;
    grid-auto-rows: auto;
    gap: 20px;
    // @media only screen and (max-width: 960px) {
    //   margin-top: 56px;
    //   gap: 23px;
    // }
    .container-box-wrapper {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      width: 100%;
      .container-box-header {
        margin: 20px 0;
        max-width: 226px;
        width: 100%;
      }
      .container-box {
        width: 100%;
        box-sizing: border-box;
      }
    }
    .container-box {
      border-radius: var(--box-radius);
      background: #242431;
      &.grid {
        padding: 20px 20px 30px;
        @media only screen and (max-width: 960px) {
          padding: 20px;
        }
        .grid__inner {
          display: grid;
          grid-auto-rows: auto;
          @media only screen and (max-width: 960px) {
            gap: 20px;
          }
          .row-item {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 20px;
            &.two-row {
              grid-template-columns: repeat(2, 1fr);
            }
            @media only screen and (max-width: 960px) {
              grid-template-columns: 1fr !important;
              grid-template-rows: 1fr !important;
              &:last-of-type li:last-of-type {
                @media only screen and (max-width: 960px) {
                  border-bottom: none;
                  padding-bottom: 0;
                }
              }
            }
            &:not(:first-of-type) {
              > li {
                padding-top: 20px;
                @media only screen and (max-width: 960px) {
                  padding-top: 0;
                }
              }
            }
            &:not(:last-of-type) {
              > li {
                &::after {
                  content: '';
                  display: block;
                  margin-top: 20px;
                  height: 1px;
                  width: calc(100% - 20px);
                  background-color: #59596B;
                  @media only screen and (max-width: 960px) {
                    display: none;
                  }
                }
              }
            }
            > li {
              @media only screen and (max-width: 960px)  {
                border-bottom: 1px solid #59596B;
                padding-bottom: 20px;
              }
              &:not(:last-of-type) {
                border-right: 1px solid #59596B;
                @media only screen and (max-width: 960px) {
                  border-right: none;
                }
              }
              @include flex(flex-start, flex-start);
              .title {
                @include flex(flex-start, center, row);
                gap: 4px;
              }
              .inner {
                margin-top: 12px;
              }
              .title, .inner {
                p {
                  @include string(16px, normal, normal, 600, left);
                }
              }
            }
          }
        }
      }
      &.solo {
        padding: 15px 20px;
      }
    }
  }
}