.footer {
  @include flex;
  background-color: var(--footer-bg);
  &__inner {
    @include flex(space-between, center, row);
    // display: grid;
    // grid-template-columns: 1fr 2.2fr;
    // gap: 20px;
    width: calc(100% - 6vw);
    padding: 72px 3vw;
    // padding: rem(72) 3vw rem(160);
    max-width: var(--G-max-width);
    // @include breakpoint(tablet) {
    //   @include flex(flex-start, flex-start);
    //   flex-wrap: wrap;
    //   gap: 0;
    // }
    @include breakpoint(mobile) {
      @include flex;
      width: calc(100% - 32px);
      padding: 56px 16px 80px;
    }
    .logo {
      object-fit: contain;
      height: 29px;
      // @include breakpoint(tablet) {
        // margin-bottom: 32px;
      // }
      @include breakpoint(mobile) {
        height: 17px;
        margin-bottom: 16px;
      }
    }

    .social-wrapper {
      @include flex(flex-end, center, row);
      flex-wrap: wrap;
      gap: 16px;
      li {
        @each $icon in discord, medium, telegram, x, youtube, zealy {
          &.#{$icon} {
            @include same(24px);
            cursor: pointer;
            background: url($G-path-icon + 'social-#{$icon}.svg') $G-img-contain;
          }
        }
      }
    }
    // .link-wrapper {
    //   display: grid;
    //   grid-template-columns: repeat(auto-fill, minmax(135px, 1fr));
    //   grid-auto-flow: dense;
    //   gap: rem(40);
    //   transform: translateY(rem(7));
    //   width: 100%;
    //   // padding-right: rem(50);
    //   @include breakpoint(mobile) {
    //     gap: 56px 37px;
    //     width: 100%;
    //   }
    //   .link-group {
    //     @include flex(flex-start, flex-start);
    //     &__title {
    //       @include string(16px, normal, normal, 500, left, var(--text-primary));
    //       margin-bottom: 23px;
    //     }
    //     &__item {
    //       display: grid;
    //       grid-auto-rows: auto;
    //       gap: 23px;
    //       li {
    //         p {
    //           @include string(16px, normal, normal, 400, left, var(--text-primary));
    //           cursor: pointer;
    //         }
    //       }
    //     }
    //   }
    // }
  }
}