.wrapper {
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  position: relative;
  .container {
    @include flex;
    // padding-top: var(--G-header-hegiht);
    // &.double-head {
      // padding-top: calc(var(--G-header-hegiht) * 2);
    // }
  }
}

.g-max-width {
  width: calc(100% - 6vw);
  padding-left: 3vw;
  padding-right: 3vw;
  max-width: var(--G-max-width);
  @include breakpoint(mobile) {
    width: 100%;
    max-width: calc(100% - 32px);
    padding: 0;
  }
}

.info-icon {
  @include same(16px);
  background: url($G-path-icon + 'info.svg') $G-img-contain;
}

.section-layout {
  padding-top: rem(107);
  padding-bottom: 20vh;
  @include breakpoint(mobile) {
    padding-top: 48px;
  }
  &__header {
    @include flex(space-between, flex-end, row);
    margin-bottom: 16px;
    &.option-header {
      display: grid;
      grid-template-columns: auto 196px;
      gap: 20px;
      align-items: center;
      justify-content: space-between;
      @include breakpoint(mobile) {
        grid-template-columns: auto 126px;
      }
    }
    @include breakpoint(mobile) {
      @include flex(flex-start, flex-start);
    }
    .section-title {
      @include string(rem(36), normal, normal, 600, left);
      &.no-icon {
        display: block;
      }
      display: grid;
      grid-template-columns: rem(36) auto;
      gap: rem(16);
      align-items: center;
      @include breakpoint(mobile) {
        font-size: 24px;
        grid-template-columns: 24px auto;
        gap: 8px;
      }
      @each $icon in latest-play, latest-txs, tokens-nfts, resources {
        &.#{$icon} {
          &::before {
            @include same(rem(36));
            background: url($G-path-icon + 'content-header-#{$icon}.svg') $G-img-contain;
            content: '';
            display: block;
            @include breakpoint(mobile) {
              @include same(24px);
            }
          }
        }
      }
    }
    .section-length {
      @include string(12px, normal, normal, 400, right);
      @include breakpoint(mobile) {
        margin-top: 4px;
        text-align: left;
      }
      span {
        color: #8571FF;
      }
    }
  }
  &__body {
    border-radius: var(--s-box-radius);
    background: #242431;
    padding: 48px 20px 32px;
    @include breakpoint(mobile) {
      padding: 24px 16px 40px;
      .m-table-inner .m-table-inner__item  {
        padding: 18px 6px;
      }
    }
  }
}


.error-wrapper {
  @include flex;
  height: 80dvh;

  .pageNotFound-wrapper {
    @include flex;
    @include breakpoint(mobile) {
      padding: 0 16px;
    }
    .pageNotFound-inner {
      @include flex;
      margin-bottom: rem(32);
      p {
        @include string(rem(24), rem(32), normal, 500, center);
        margin: 8px 0;
        @include breakpoint(mobile) {
          margin: 6px 0 4px;
        }
      }
      span {
        @include string(14px, 18px, 0.16px, 400, center);
        @include breakpoint(mobile) {
          @include string(12px, 16px, 0.12px, 400, center);
        }
      }
    }
  }
}

// .loading-wrapper {
//   @include flex;
//   img {
//     @include same(160px);
//   }
// }