* {
  font-family: 'Roboto Mono';
  -webkit-tap-highlight-color: transparent;

  img {
    -webkit-user-drag: none;
  }
}

:root {
  font-size: 18px;
  @include breakpoint(tablet) {
    font-size: 16px;
  }
  @include breakpoint(mobile) {
    font-size: 14px;
  }

  --G-max-width: 1304px;
  --G-header-hegiht: 4.44rem;
  // --G-header-hegiht-m: 56px;

  --box-radius: 8px;
  --s-box-radius: 4px;

  --header-bg: rgba(28, 32, 45, 1);
  --box-bg: rgba(36, 36, 49, 1);
  --bg: #151523;
  --footer-bg: #0C0C13;
  --white: #fff;
  --text-primary: #fff;

  @include breakpoint(mobile) {
    --G-header-hegiht: 48px;
  }
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #59596b #151523;
  /* Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: 16px;
  }
  &::-webkit-scrollbar-track {
    background: #1c202d;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #59596b;
    border-radius: 10px;
    border: 3px solid #ffffff;
  }
  @include breakpoint(mobile) {
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 0px;
      border: none;
    }
  }
}

body {
  overflow: hidden;
  font-size: calc(50 / 16) * 1px;
}

html,
body,
#root {
  background-color: var(--bg);
  color: var(--white);
  height: 100%;
  width: 100%;
	::selection {
		background-color: #8571FF;
		color: #0E0E18;
	}
}

html {
  overflow-x: hidden;
  font-size: 1rem;
}

span,
p,
a,
h1,
h2,
h3,
h4,
h5,
h6,
button {
  transform: skew(-0.1deg);
}
// textarea,
// input,
// select

a,
textarea,
input,
select,
button {
  font-size: 18px;
  @include breakpoint(tablet) {
    font-size: 16px;
  }
  @include breakpoint(mobile) {
    font-size: 14px;
  }
}

textarea,
input,
select {
  outline: none;
  border: none;
  background: none;
}

button {
  outline: none;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
}

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
}

body {
  line-height: 1;
}

nav ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
  color: var(--text-primary) !important;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input,
select {
  vertical-align: middle;
}

ol,
ul {
  list-style: none;
}
